// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgGraphUp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 20.75a.75.75 0 0 1-.75-.75V4a.75.75 0 0 1 1.5 0v10.769l6.737-6.316a.75.75 0 0 1 1.043.017L15 10.94l3.97-3.97a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-2.487-2.486-7.233 6.78v2.426H20a.75.75 0 0 1 0 1.5z"
      clipRule="evenodd"
    />
  </svg>
);
export const GraphUpIcon = forwardRef(SvgGraphUp);
