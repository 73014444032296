// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgEditPencil = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.616 8.945a2.75 2.75 0 0 0 0-3.89l-1.414-1.414a2.75 2.75 0 0 0-3.89 0L4.218 14.737a2.75 2.75 0 0 0-.795 1.702l-.242 2.74a1.75 1.75 0 0 0 1.897 1.898l2.74-.242a2.75 2.75 0 0 0 1.703-.795zm-1.06-2.829a1.25 1.25 0 0 1 0 1.768l-.95.95-3.182-3.182.95-.95a1.25 1.25 0 0 1 1.767 0zm-5.193.596 3.182 3.182L8.46 18.98a1.25 1.25 0 0 1-.774.36l-2.74.243a.25.25 0 0 1-.272-.271l.243-2.74a1.25 1.25 0 0 1 .36-.774z"
      clipRule="evenodd"
    />
  </svg>
);
export const EditPencilIcon = forwardRef(SvgEditPencil);
